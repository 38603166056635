import { LocalStorageKeys } from "../authServices/jwt/libs/LocalStorage.enum";

export const saveConfigToLocalStorage = (config: any) => {
  try {
    localStorage.setItem(
      LocalStorageKeys.TWC_MODULES,
      JSON.stringify(config?.data?.twcModules || [])
    );

    localStorage.setItem(
      LocalStorageKeys.TWC_CONFIG,
      JSON.stringify(config?.data || {})
    );
  } catch (error) {
    console.error("Error saving config:", error);
    throw error;
  }
};