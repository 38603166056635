import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Form, Input, Button, message, Checkbox, Row, Col } from "antd";
import { SignUpContainer } from "./sign-up.styles";
import { getRegister } from "../../../utility/store/middlerware/authMiddleware";
import { GetAuthStateContext } from "../../../utility/context/authContext";
import Loading from "../../UI/Loading";
import {  layout } from "../../../configs/layout/authLayout";

interface ISignUp {
    email: string;
    password: string;
    retype_password: string;
    agreement: boolean;
    username: string;
    api_key: string;
}

const SignUpForm = () => {
    const history = useHistory();
    const { dispatch, authState } = GetAuthStateContext();
    const validateMessages = {
        // eslint-disable-next-line
        required: "${label} is required!",
        types: {
            // eslint-disable-next-line
            email: "${label} is not a valid email!",
        },
    };
    const onFinish = (values: ISignUp): void => {
        const { email, password, api_key } = values;

        getRegister(dispatch, { email, password, api_key }).then((res : any) => {
         //   history.push("/auth");
            // if (res.result) {
            //     message.success(res.message);
            // } else if (!res.result) {
            //     message.error(res.message);
            // }
        });
    };

    const onFinishFailed = (errorInfo: any): void => {};

    return (
        <SignUpContainer className="btn-custom link-custom form-custom">
            <h1>Register</h1>
            <Form
                validateMessages={validateMessages}
                {...layout}
                layout="horizontal"
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <Form.Item
                    label="Email"
                    labelAlign="right"
                    name="email"
                    rules={[
                        {
                            required: true,
                        },
                        {
                            validator: (_, value) => {
                                if (!value) {
                                    return Promise.resolve();
                                }
                                //eslint-disable-next-line
                                const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                                const result = emailRegex.test(value);
                                if (result) {
                                    return Promise.resolve();
                                } else {
                                    return Promise.reject(
                                        new Error("Invalid email")
                                    );
                                }
                            },
                        },
                    ]}
                >
                    <Row gutter={[16, 0]}>
                        <Col xs={24} sm={24} md={16}>
                            <Input size="middle" />
                        </Col>
                    </Row>
                </Form.Item>

                <Form.Item
                    label="Password"
                    labelAlign="right"
                    name="password"
                    rules={[
                        {
                            required: true,
                        },
                        {
                            validator: (_, value) => {
                                if (!value) {
                                    return Promise.resolve();
                                }

                                if (value.length >= 5) {
                                    return Promise.resolve();
                                } else {
                                    return Promise.reject(
                                        new Error(
                                            "Password must contain at least 5 characters"
                                        )
                                    );
                                }
                            },
                        },
                    ]}
                >
                    <Row gutter={[16, 0]}>
                        <Col xs={24} sm={24} md={16}>
                            <Input.Password size="middle" />
                        </Col>
                    </Row>
                </Form.Item>
                <Form.Item
                    label="Re-type password"
                    labelAlign="right"
                    name="retype_password"
                    dependencies={["password"]}
                    rules={[
                        {
                            required: true,
                            message: "Please confirm your password!",
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (
                                    !value ||
                                    getFieldValue("password") === value
                                ) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(
                                    "The two passwords that you entered do not match!"
                                );
                            },
                        }),
                    ]}
                >
                    <Row gutter={[16, 0]}>
                        <Col xs={24} sm={24} md={16}>
                            <Input.Password size="middle" />
                        </Col>
                    </Row>
                </Form.Item>
                <Form.Item
                    label="Shopify key"
                    labelAlign="right"
                    name="api_key"
                    rules={[
                        {
                            required: true,
                        }
                    ]}
                >
                    <Row gutter={[16, 0]}>
                        <Col xs={24} sm={24} md={16}>
                            <Input size="middle" />
                        </Col>
                    </Row>
                </Form.Item>
                <Form.Item
                    name="agreement"
                    valuePropName="checked"
                    rules={[
                        {
                            validator: (_, value) =>
                                value
                                    ? Promise.resolve()
                                    : Promise.reject(
                                          "You need to accept the agreement."
                                      ),
                        },
                    ]}
                    className="agreement"
                    label="Agreement"
                >
                    <Row gutter={[16, 0]}>
                        <Col xs={24} sm={24} md={16}>
                            <div className="checkbox--container">
                                <Checkbox>
                                    I agree to the{" "}
                                    <Link to="#">Terms of Use</Link> and{" "}
                                    <Link to="#">Privacy Policy </Link>
                                </Checkbox>
                            </div>
                        </Col>
                    </Row>
                </Form.Item>

                <Form.Item label="Submit" className="mb-0 footer">
                    <Row gutter={[16, 0]}>
                        <Col xs={24} sm={24} md={16}>
                            <div className="btn--container">
                                <Button
                                    type="primary"
                                    className="button"
                                    htmlType="submit"
                                >
                                    Sign up
                                </Button>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                            <span>
                                Already has an account? &nbsp;
                                <Link to="/auth/sign-in">Login here</Link>
                            </span>
                        </Col>
                    </Row>
                </Form.Item>
            </Form>
            {authState.pending && <Loading />}
        </SignUpContainer>
    );
};

export default React.memo(SignUpForm);
