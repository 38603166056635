import { Roles } from "../../configs/rolePemisions/roles.enum";
import { checkModuleAccess, Modules } from "../helpers/checkModuleAccess";

export type SubCategory = { key: string; tooltrip: string };

export const getFilterSubmenuByRole = (
  roles: string[],
  items: SubCategory[]
) => {
  let filteredItems = items;

  if (!checkModuleAccess(Modules.QUOTES)) {
    filteredItems = filteredItems.filter((item) => item.key !== "quotes" && item.key !== "quote-conversion");
  }

  if (!checkModuleAccess(Modules.STORE_POS)) {
    filteredItems = filteredItems.filter((item) => item.key !== "staff");
  }

  if (!checkModuleAccess(Modules.PREFERENCES)) {
    filteredItems = filteredItems.filter((item) => item.key !== "preferences");
  }

  if (!checkModuleAccess(Modules.MESSAGES) && !checkModuleAccess(Modules.STORE_POS)) {
    filteredItems = filteredItems.filter((item) => item.key !== "customer-message");
  }

  return filteredItems;
};

