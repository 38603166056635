enum CategoryKeys {
  WISHLIST_DASHBOARD = "wishlist-dashboard",
  TOP_STORES_DASHBOARD = "top-stores-dashboard",
  STORE_STAFF_DASHBOARD = "store-&-staff-dashboard",
  QUOTE_DASHBOARD = "quote-dashboard",
  QUOTE_REPORT = "/reporting/quotes",
  QUOTE_CONVERSION_REPORT = "/reporting/quote-conversion",
  PREFERENCES_REPORT = "/reporting/preferences",
  SUBSCRIPTION = "subscription",
  INTEGRATIONS = "integrations",
  APP_CONFIGURATIONS = "app-configurations",
  NOTIFICATIONS = "notifications",
  USER_ADMINISTRATION = "user-administration",
  REPORTING = "reporting",
  POS_CONFIG = "pos-config",
  DOCUMENTATION = "documentation",
  LOGOUT = "logout",
  MANAGE_STORES = 'manage-stores-&-regions',
  CUSTOMER_MESSAGE = "/reporting/customer-message",
  STORE_MESSAGE_TEMPLATES = "store-message-templates",
  WEBHOOK_MANAGEMENT = "webhook-management",
}

export { CategoryKeys };
