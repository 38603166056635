import { axiosDashboard } from "../../../views/pages/Dashboard/config/AxiosSetting";

class TenantService {
   getAllUserTenants() {
      return axiosDashboard.get("/services/eventcollector/api/v1/custom/getAllTenants");
   }
   getConfig(tenant: string | null) {
      return axiosDashboard.get("/services/eventcollector/api/v1/custom/configs", {
         headers: {
            "X-TWC-Tenant": tenant,
         },
      });
   }
   getMemberTenants(tenant: string | null) {
      return axiosDashboard.get(`/services/eventcollector/api/v1/tenant/getMemberTenants/${tenant}`, {
         headers: {
            "X-TWC-Tenant": tenant,
         },
      });
   }
}

export const tenantService = new TenantService();
